import {
	OPEN_CUSTOM_ALERT,
	OPEN_CUSTOM_CONFIRMATION,
	OPEN_CONFIRM_REDIRECT
} from 'definitions/javascriptEventsDefinitions';
import {
	isMobile as isDeviceMobile,
	isAndroid,
	isIOS,
	osName,
	osVersion,
	isTablet,
	isMobileOnly,
	isSmartTV,
	isWearable,
	browserName,
	engineName,
	engineVersion,
	isConsole,
	browserVersion
} from 'react-device-detect';
import config from 'react-global-configuration';
import {
	CONSOLEDEVICETYPE,
	DESKTOPDEVICETYPE,
	MOBILEDEVICETYPE,
	SMARTTVDEVICETYPE,
	TABLETDEVICETYPE,
	WEARABLEDEVICETYPE
} from 'definitions/deviceTypeDefinitions';

import {
	PRODUCTIONENVIRONMENT,
	DEVELENVIRONMENT,
	PREPRODUCTIONENVIRONMENT,
	COMUNITYENVIRONMENT,
	PREBETAENVIRONMENT,
	AMATEURHOST,
	DEVELHOST,
	LOCALHOSTHOST,
	PREPRODHOST,
	PREBETAPRODHOST
} from 'definitions/EnvironmentsDefinitions';
import {REDIRECT_TYPE_NEW} from "../definitions/MiscDefinitions";

const getEnvironment = () => {
	const host = window.location.host;

	if (host.includes(AMATEURHOST)) return PRODUCTIONENVIRONMENT;
	if (host.includes(DEVELHOST)) return DEVELENVIRONMENT;
	if (host.includes(LOCALHOSTHOST)) return DEVELENVIRONMENT;
	if (host.includes(PREPRODHOST)) return PREPRODUCTIONENVIRONMENT;
	if (host.includes(PREBETAPRODHOST)) return PREBETAENVIRONMENT;

	return COMUNITYENVIRONMENT;
};

const isBrowserSupported = () => {
	if (isIOS) {
		const osVersion = parseInt(osVersion);
		if (osVersion < config.get('minimumIOSOsVersion')) return false;
	}
	return true;
};
const generateOperatingSystem = () => {
	return `${osName} ${osVersion}`;
};

const isBrowserFirefox = () => {
	if (browserName) return browserName.toLowerCase().includes('firefox');
	return false;
};

const isBrowserSafari = () => {
	if (browserName) return browserName.toLowerCase().includes('safari');
	return false;
};
const generateBrowser = () => {
	return `${browserName} ${browserVersion} (${engineName} ${engineVersion})`;
};
const generateUserAgent = () => {
	return navigator.userAgent;
};
const generateDeviceType = () => {
	if (isTablet) return TABLETDEVICETYPE;

	if (isMobileOnly) return MOBILEDEVICETYPE;

	if (isSmartTV) return SMARTTVDEVICETYPE;

	if (isWearable) return WEARABLEDEVICETYPE;

	if (isConsole) return CONSOLEDEVICETYPE;

	return DESKTOPDEVICETYPE;
};
const isMobile = () => {
	return isDeviceMobile;

	// return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
};
const getIsTablet = () => {
	return isTablet;
};
const isDeviceWindows = () => {
	return osName.toLowerCase().includes('windows');

	// return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
};
const isDeviceMac = () => {
	return osName.toLowerCase().includes('mac');

	// return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
};
function isDeviceiOS() {
	return isIOS;
	// return (
	// 	['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
	// 		navigator.platform
	// 	) ||
	// 	// iPad on iOS 13 detection
	// 	(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	// );
}

const isDeviceAndroid = () => {
	return isAndroid;
	// const ua = navigator.userAgent.toLowerCase();
	// const isAndroid = ua.indexOf('android') > -1; //&& ua.indexOf("mobile");
	//
	// return isAndroid;
};
const getDeviceForAds = () => {
	return isMobileOnly || (isTablet && window.innerWidth < 840) ? MOBILEDEVICETYPE : DESKTOPDEVICETYPE;
};
function launchCustomAlert(content, title = '') {
	window.dispatchEvent(
		new CustomEvent(OPEN_CUSTOM_ALERT, {
			detail: {
                customAlertTitle: title,
                customAlertContent: content
            }
		}
	));
}

function launchCustomConfirmation(title, content, url) {
	window.dispatchEvent(
		new CustomEvent(OPEN_CUSTOM_CONFIRMATION, {
			detail: {
				customConfirmationTitle: title,
				customConfirmationContent: content,
				customConfirmationUrl: url
			}
		})
	);
}
function launchConfirmRedirect(pathname, location, action) {
	window.dispatchEvent(
		new CustomEvent(OPEN_CONFIRM_REDIRECT, {
			detail: {
				pathname: pathname,
				location: location,
				action: action
			}
		})
	);
}

function validateEmail(email) {
	if (!email) return true;
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

function arrayRemove(arr, value) {
	return arr.filter(function (ele) {
		return ele != value;
	});
}
function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
function validatePassword(password) {
	if (!password) return true;
	const stringToValidate = String(password).toLowerCase();
	const re = new RegExp('^[a-zA-Z0-9]{6,}$');

	if (re.test(stringToValidate)) {
		const containsANumber = /\d/;
		const containsALetter = /[aA-zZ]/;
		if (containsANumber.test(stringToValidate) && containsALetter.test(stringToValidate)) {
			return true;
		}
	}

	return false;
}

function validateUsername(username) {
	if (!username) return true;
	const stringToValidate = String(username).toLowerCase();
	const re = new RegExp('^[a-zA-Z0-9-_]{4,15}$');

	return re.test(stringToValidate);
}

function scrollToTopSmoothly() {
	// Page must have 	smoothscroll.polyfill();
	window.scrollTo({ top: 0, behavior: 'smooth' });
}

const toCamel = (s) => {
	return s.replace(/([-_][a-z])/gi, ($1) => {
		return $1.toUpperCase().replace('-', '').replace('_', '');
	});
};

const convertObjectPropertiesToArray = (obj) => {
	let result = [];
	for (let prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			result = [...result, obj[prop]];
		}
	}

	return result;
};

function toFixed(num, fixed) {
	var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
	return num.toString().match(re)[0];
}

const getTimeStamp = (milliseconds) => {
	if (milliseconds) {
		return new Date(new Date().getTime() + milliseconds);
	}
	return null;
};


const executeRedirection = (history, params) => {
	if (params
		&& typeof params === 'object'
		&& params.type
		&& typeof params.route === 'string') {
		if (params.type === REDIRECT_TYPE_NEW) {
			history.push(params.route)
		} else {
			window.location.href = params.route;
		}
	}
}

const getSecondsLeft = (timeStamp) => {
	if (!timeStamp) return 0;

	const timeStampNow = new Date().getTime();
	const diff = Math.floor((timeStamp - timeStampNow) / 1000);

	return diff;
};
function versionCompare(v1, v2, options) {
	var lexicographical = options && options.lexicographical,
		zeroExtend = options && options.zeroExtend,
		v1parts = v1.split('.'),
		v2parts = v2.split('.');

	function isValidPart(x) {
		return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
	}

	if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
		return NaN;
	}

	if (zeroExtend) {
		while (v1parts.length < v2parts.length) v1parts.push('0');
		while (v2parts.length < v1parts.length) v2parts.push('0');
	}

	if (!lexicographical) {
		v1parts = v1parts.map(Number);
		v2parts = v2parts.map(Number);
	}

	for (var i = 0; i < v1parts.length; ++i) {
		if (v2parts.length == i) {
			return 1;
		}

		if (v1parts[i] == v2parts[i]) {
			continue;
		} else if (v1parts[i] > v2parts[i]) {
			return 1;
		} else {
			return -1;
		}
	}

	if (v1parts.length != v2parts.length) {
		return -1;
	}

	return 0;
}
export {
	isMobile,
	isDeviceiOS,
	validateEmail,
	validatePassword,
	validateUsername,
	scrollToTopSmoothly,
	arrayRemove,
	generateDeviceType,
	isBrowserSupported,
	convertObjectPropertiesToArray,
	launchCustomAlert,
	launchCustomConfirmation,
	launchConfirmRedirect,
	isBrowserFirefox,
	toFixed,
	generateOperatingSystem,
	capitalize,
	generateUserAgent,
	isDeviceAndroid,
	generateBrowser,
	getEnvironment,
	executeRedirection,
	getTimeStamp,
	getSecondsLeft,
	isDeviceWindows,
	isDeviceMac,
	isBrowserSafari,
	versionCompare,
	getDeviceForAds,
	getIsTablet
};
